import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Hero from "../components/hero"
import Contact from "../components/contact"


const TheoriePage = ({ data: { prismicTheorie } }) => (
<Layout>
<SEO title={prismicTheorie.data.title.text} /> 
<Hero>
<h1 class="title has-text-light">
	{prismicTheorie.data.title.text}
</h1>
</Hero>

{/* Main content */}
<div dangerouslySetInnerHTML={{__html: prismicTheorie.data.content.text }} />

{/* Theorie video */}
<section class="section has-background-neutral-9">
	<h2 class="title has-text-centered">Het nieuwe theorie-examen vanaf 1 juli 2017</h2>
	<div class="container">
		<div class="columns is-centered">
			<div class="column">
				<div class="video-container">
				<iframe width="100%" height="auto" src="https://www.youtube.com/embed/F2YiqcQ-aog" frameborder="0" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</section>

</Layout>
)

export default TheoriePage

export const pageQuery = graphql`
	query TheoriePageQuery {
		prismicTheorie {
			data {
				title {
					text
				}
				content {
					text
				}
			}
		}
	}
`
